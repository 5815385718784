export const isEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isTaxNumberRegex = /[0-9]{2,3}\/[0-9]{3,4}\/[0-9]{4,5}/;
export const isValidInstitutionNumberRegex = /^[0-9]{9}$/;
export const isPhoneNumberRegex = /^(\+|\d|-)*$/;

export const phoneNumberPrefixRegex = /^[+0].*$/;
export const mobileNumberPrefixRegex = /^(00|\+)(49)(15|16|17)|^(0)(15|16|17)/;

export const isPostcodeRegex = /^\d{5}$/;

export const isPostcodeInternationalRegex = /^[a-zA-Z0-9\s\-/,]{3,12}$/;

export const isStrongPassword =
  /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^.&*()_+='"\\;:,<>/?~{[\]}|-]*$/;

export const isNumberRegex = /^\d*$/;

export const isOnlyLettersRegex = /^[a-zA-ZäöüÄÖÜß0-9' -_]+$/;

export const containsUpperCaseRegex = /^(?=.*[A-Z]).+$/;

export const containsLowerCaseRegex = /^(?=.*[a-z]).+$/;

export const containsNumericCharRegex = /^(?=.*\d).+$/;

export const containsNonAlphaNumericRegex = /^(?=.*[^a-zA-Z0-9]).+$/;

export const containsInquiryIdRegex =
  /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b/;

export const isNameRegex = /^[\p{L}\s'-]+$/u;
