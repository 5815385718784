import { useLoadInquiriesList } from 'api/CompeonReverseApi/operation/queryHooks';
import { IInquiryListOptions } from 'models/InquiryList.model';
import { useInquiriesListContext } from 'modules/InquiriesListProvider';
import { SORT_DIRECTION } from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';

const getSortDirection = (currentOptions: IInquiryListOptions, columnId: string) => {
  const { DESC, ASC } = SORT_DIRECTION;
  if (currentOptions.sortBy !== columnId) {
    return DESC;
  }
  return currentOptions.sortDirection === DESC ? ASC : DESC;
};

export const useInquiryTable = () => {
  const { tableOptions, setTableOptions } = useInquiriesListContext();
  const {
    data,
    isLoading,
    isSuccess: isInitialized,
  } = useLoadInquiriesList({
    variables: { options: tableOptions },
  });

  const onPageSizeChange = (newPageSize: number) =>
    setTableOptions({ pageSize: newPageSize, currentPage: 1 });

  const totalPages = data?.options?.pageCount;
  const currentPage = data?.options.currentPage;
  const pageSize = data?.options?.pageSize;

  const onPaginationChange = (newPage: number) => setTableOptions({ currentPage: newPage });

  const sortBy = data?.options?.sortBy;
  const sortDirection = data?.options?.sortDirection;

  const onSortChange = (columnId: string) =>
    setTableOptions({
      sortBy: columnId,
      sortDirection: getSortDirection(tableOptions, columnId),
    });

  const onSearchChange = (searchValue: string) =>
    setTableOptions({
      currentPage: 1,
      filterBy: { ...tableOptions.filterBy, companyName: searchValue },
    });

  return {
    totalPages,
    currentPage,
    pageSize,
    onPageSizeChange,
    onPaginationChange,
    isLoading,
    isInitialized,
    sortBy,
    sortDirection,
    onSortChange,
    onSearchChange,
    data,
  };
};
