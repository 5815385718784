import React from 'react';

import FormRowBase from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { StyledHalfRow } from 'pages/operationPortal/OperationInquiryList/FiltersDrawer/styles';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

const IndicativeConditionFactoringLineFilters = () => {
  const t = useTranslations();
  const { isInteger } = useFieldValidators();

  return (
    <FormRowBase>
      <StyledHalfRow>
        <InputWithField
          name={INQUIRY_FILTERS.INDICATIVE_CONDITION_FACTORING_LINE_FROM}
          validate={isInteger}
          caption={t(
            'pages.inquiryList.filtersDrawer.fields.indicativeConditionFactoringLineFrom.caption',
          )}
          isClearable
        />
      </StyledHalfRow>

      <StyledHalfRow>
        <InputWithField
          name={INQUIRY_FILTERS.INDICATIVE_CONDITION_FACTORING_LINE_TO}
          validate={isInteger}
          caption={t(
            'pages.inquiryList.filtersDrawer.fields.indicativeConditionFactoringLineTo.caption',
          )}
          isClearable
        />
      </StyledHalfRow>
    </FormRowBase>
  );
};

export default IndicativeConditionFactoringLineFilters;
