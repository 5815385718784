import React from 'react';

import FormRowBase from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { INQUIRY_FILTERS } from 'modules/InquiryTable/constants';
import { translations } from 'new/form/common/types';
import { StyledFormRow } from 'pages/operationPortal/OperationInquiryList/FiltersDrawer/styles';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

import BankAdvisorFilter from './BankAdvisorFilter';
import CompanyCountryFilter from './CompanyCountryFilter';
import IndicativeConditionFactoringLineFilters from './IndicativeConditionFactoringLineFilters';
import StatusFilter from './StatusFilter';

const MmvFilters = () => {
  const t = useTranslations();
  const fields = translations.pages.inquiryList.filtersDrawer.fields;

  return (
    <>
      <StatusFilter />
      <StyledFormRow>
        <InputWithField
          name={INQUIRY_FILTERS.ZIP_CODE_AREA}
          caption={t(fields.zipCodeArea.caption)}
          isClearable
        />
      </StyledFormRow>
      <StyledFormRow>
        <InputWithField
          name={INQUIRY_FILTERS.VPP_ID}
          caption={t(fields.vppId.caption)}
          isClearable
        />
      </StyledFormRow>
    </>
  );
};

const DzbFilters = () => {
  const t = useTranslations();
  const { optional } = useFieldValidators();
  const fields = translations.pages.inquiryList.filtersDrawer.fields;
  return (
    <>
      <FormRowBase>
        <StyledFormRow>
          <InputWithField
            name={INQUIRY_FILTERS.COMPANY_PARTNER_REFERENCE_ID}
            validate={optional}
            caption={t(fields.gpNumber.caption)}
            isClearable
          />
        </StyledFormRow>

        <StyledFormRow>
          <InputWithField
            name={INQUIRY_FILTERS.COMPANY_NAME}
            validate={optional}
            caption={t(fields.gpName.caption)}
            isClearable
          />
        </StyledFormRow>
      </FormRowBase>
      <StyledFormRow>
        <InputWithField
          name={INQUIRY_FILTERS.ASSOCIATION_NAME}
          validate={optional}
          caption={t(fields.association.caption)}
          isClearable
        />
      </StyledFormRow>
      <CompanyCountryFilter />
      <BankAdvisorFilter />
      <StatusFilter />
    </>
  );
};

const CbBankFilters = () => {
  const t = useTranslations();
  const { caption } = translations.pages.inquiryList.filtersDrawer.fields.lane;
  const { pleaseChoose } = translations.placeholders;
  const { contract, lead } = translations.inquiryType.cbBank.laneLabels;

  const options: Array<SelectOption> = [
    { value: InquiryLane.contract, label: t(contract) },
    { value: InquiryLane.lead, label: t(lead) },
  ];

  return (
    <>
      <BankAdvisorFilter />
      <StyledFormRow>
        <SelectWithField
          name={INQUIRY_FILTERS.LANE}
          caption={t(caption)}
          placeholder={t(pleaseChoose)}
          options={options}
          isClearable
        />
      </StyledFormRow>
    </>
  );
};

const BfsServiceFilters = () => {
  return (
    <>
      <IndicativeConditionFactoringLineFilters />
    </>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.mmv]: MmvFilters,
  [InquiryType.dzb]: DzbFilters,
  [InquiryType.cbBank]: CbBankFilters,
  [InquiryType.bfsService]: BfsServiceFilters,
  default: StatusFilter,
});
